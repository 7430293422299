var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"parent__class mt-6"},[(_vm.step === 2)?_c('div',{staticClass:"back_option",on:{"click":function($event){return _vm.stepHandle(1)}}},[_c('span',{staticClass:"icon-arrow-left"}),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('Back')))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"steps-form"},[_c('div',{staticClass:"steps-row setup-panel"},[_c('div',{staticClass:"steps-step"},[_c('button',{staticClass:"p-0",class:_vm.step == 2 ? 'btn-circle-filled' : 'btn-circle',attrs:{"type":"button"}},[(_vm.step === 2)?_c('span',{staticClass:"icon-color icon-check"}):_vm._e(),_vm._v(" "),(_vm.step === 1)?_c('span',[_vm._v("1")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"steps-step"},[_c('button',{staticClass:"p-0 btn-circle",attrs:{"type":"button","disabled":true}},[_vm._v("\n          2\n        ")])])])]),_vm._v(" "),_c('div',{staticClass:"formWrap"},[_c('ValidationObserver',{ref:"formRef"},[_c('form',{staticClass:"form form_registration",attrs:{"name":"form_registration","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[(_vm.step === 1)?_c('div',{staticClass:"title__form"},[_c('h1',[_vm._v(_vm._s(_vm.element.content.header))]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.element.bodytext)}})]):[_c('div',{staticClass:"title__form",domProps:{"innerHTML":_vm._s(_vm.element.personalDetails)}}),_vm._v(" "),_c('div',{staticClass:"heading__text"},[_c('h1',[_vm._v(_vm._s(_vm.$t('Personal information')))])])],_vm._v(" "),_c('keep-alive',[(_vm.step === 1)?_c('ValidationProvider',{attrs:{"rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element custom_input",attrs:{"placeholder":_vm.$t('name.surname@email.com'),"label":_vm.$t('Email address *'),"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","autocomplete":"email"},model:{value:(_vm.formCopy.email),callback:function ($$v) {_vm.$set(_vm.formCopy, "email", $$v)},expression:"formCopy.email"}})]}}],null,false,981697484)}):_vm._e()],1),_vm._v(" "),_c('keep-alive',[(_vm.step === 1)?_c('ValidationProvider',{attrs:{"rules":"required|password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-password'),expression:"'login-modal-password'"}],staticClass:"form__element custom_input",attrs:{"valid":!errors[0],"label":_vm.$t('Create password *'),"error-message":_vm.$t(errors[0]),"type":"password","autocomplete":"new-password","name":"password"},model:{value:(_vm.formCopy.password),callback:function ($$v) {_vm.$set(_vm.formCopy, "password", $$v)},expression:"formCopy.password"}})]}}],null,false,501673155)}):_vm._e()],1),_vm._v(" "),_c('keep-alive',[(_vm.step === 1)?_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","vid":"password2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-confirmPassword'),expression:"'login-modal-confirmPassword'"}],staticClass:"form__element custom_input",attrs:{"valid":!errors[0],"label":_vm.$t('Confirm password *'),"error-message":_vm.$t(errors[0]),"type":"password","autocomplete":"new-password","name":"password_repeat"},model:{value:(_vm.formCopy.confirmPassword),callback:function ($$v) {_vm.$set(_vm.formCopy, "confirmPassword", $$v)},expression:"formCopy.confirmPassword"}})]}}],null,false,1326993295)}):_vm._e()],1),_vm._v(" "),(_vm.step === 2)?[_c('div',{staticClass:"input__row"},[_c('div',{staticClass:"select__area"},[_c('ValidationProvider',{attrs:{"vid":"gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfSelect',{staticClass:"form__element custom_select gender__select",attrs:{"label":_vm.$t('Gender'),"valid":!errors[0],"errorMessage":_vm.$t(errors[0]),"placeholder":_vm.$t('Gender'),"name":"gender"},model:{value:(_vm.formCopy.gender),callback:function ($$v) {_vm.$set(_vm.formCopy, "gender", $$v)},expression:"formCopy.gender"}},[_c('SfSelectOption',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t('Male')))]),_vm._v(" "),_c('SfSelectOption',{attrs:{"value":"2"}},[_vm._v(_vm._s(_vm.$t('Female')))]),_vm._v(" "),_c('SfSelectOption',{attrs:{"value":"3"}},[_vm._v(_vm._s(_vm.$t('Not specified')))])],1)]}}],null,false,1173260179)})],1),_vm._v(" "),_c('div',{staticClass:"input__area"},[_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-title'),expression:"'login-modal-title'"}],staticClass:"form__element custom_input",attrs:{"label":_vm.$t('Title'),"placeholder":_vm.$t('Your Title (optional)'),"name":"title","autofill":"off"},model:{value:(_vm.formCopy.title),callback:function ($$v) {_vm.$set(_vm.formCopy, "title", $$v)},expression:"formCopy.title"}})],1)]),_vm._v(" "),_c('div',{staticClass:"input__row"},[_c('div',{staticClass:"w-100"},[_c('keep-alive',[_c('ValidationProvider',{attrs:{"rules":"required","vid":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-firstName'),expression:"'login-modal-firstName'"}],staticClass:"form__element custom_input",attrs:{"label":_vm.$t('First Name'),"placeholder":_vm.$t('First Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"firstname","autocomplete":"given-name"},model:{value:(_vm.formCopy.firstName),callback:function ($$v) {_vm.$set(_vm.formCopy, "firstName", $$v)},expression:"formCopy.firstName"}})]}}],null,false,1631624367)})],1)],1),_vm._v(" "),_c('div',{staticClass:"w-100"},[_c('keep-alive',[_c('ValidationProvider',{attrs:{"rules":"required","vid":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-lastName'),expression:"'login-modal-lastName'"}],staticClass:"form__element custom_input",attrs:{"label":_vm.$t('Last Name'),"placeholder":_vm.$t('Last Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"lastname","autocomplete":"family-name"},model:{value:(_vm.formCopy.lastName),callback:function ($$v) {_vm.$set(_vm.formCopy, "lastName", $$v)},expression:"formCopy.lastName"}})]}}],null,false,1809171050)})],1)],1)]),_vm._v(" "),_c('keep-alive',[_c('ValidationProvider',{attrs:{"vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-phone'),expression:"'login-modal-phone'"}],staticClass:"form__element custom_input",attrs:{"placeholder":"12345678","label":_vm.$t(
                    'Phone number (We will only call you if there are problems with the order.)'
                  ),"valid":!errors[0],"error-message":_vm.$t(errors[0]),"type":"number","autocomplete":"tel","name":"phone"},model:{value:(_vm.formCopy.phone),callback:function ($$v) {_vm.$set(_vm.formCopy, "phone", $$v)},expression:"formCopy.phone"}})]}}],null,false,1271239455)})],1),_vm._v(" "),_c('div',{staticClass:"sf-textarea w-100"},[_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-msg'),expression:"'login-modal-msg'"}],staticClass:"form__element custom_input",attrs:{"placeholder":_vm.$t('Type in your message'),"label":_vm.$t('How did you hear about TISSO'),"name":"msg"},model:{value:(_vm.formCopy.msg),callback:function ($$v) {_vm.$set(_vm.formCopy, "msg", $$v)},expression:"formCopy.msg"}})],1),_vm._v(" "),_c('div',{staticClass:"heading__text"},[_c('h1',[_vm._v(_vm._s(_vm.$t('Billing address')))])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-2"},[_c('SfCheckbox',{staticClass:"form__element",attrs:{"selected":_vm.isCompany,"label":_vm.$t('I am a company'),"name":"isCompany"},on:{"change":function($event){return _vm.handleChangeCompany($event)}}})],1),_vm._v(" "),(_vm.isCompany)?_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":"billing-company","rules":"required","slim":"","vid":"billingCompany"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element custom_input",attrs:{"label":_vm.$t('Company name'),"name":"billing-company","placeholder":_vm.$t('Company name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"autocomplete":"organization"},model:{value:(_vm.formCopy.billingCompany),callback:function ($$v) {_vm.$set(_vm.formCopy, "billingCompany", $$v)},expression:"formCopy.billingCompany"}})]}}],null,false,1945795353)})],1):_vm._e()]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","vid":"billingAddress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-billingAddress'),expression:"'login-modal-billingAddress'"}],staticClass:"form__element custom_input",attrs:{"name":"billingAddress","valid":!errors[0],"error-message":_vm.$t(errors[0]),"placeholder":_vm.$t('Street name and house number'),"label":_vm.$t('Street name and house number'),"required":"","autocomplete":"address-line1"},model:{value:(_vm.formCopy.billingAddress),callback:function ($$v) {_vm.$set(_vm.formCopy, "billingAddress", $$v)},expression:"formCopy.billingAddress"}})]}}],null,false,285560370)}),_vm._v(" "),_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-billingAdditionalInfo'),expression:"'login-modal-billingAdditionalInfo'"}],staticClass:"form__element custom_input",attrs:{"name":"billingAdditionalInfo","label":_vm.$t('Additional address information'),"placeholder":_vm.$t('Additional address information'),"autocomplete":"address-line2"},model:{value:(_vm.formCopy.billingAdditionalInfo),callback:function ($$v) {_vm.$set(_vm.formCopy, "billingAdditionalInfo", $$v)},expression:"formCopy.billingAdditionalInfo"}}),_vm._v(" "),_c('div',{staticClass:"input__row"},[_c('div',{staticClass:"w-100"},[_c('ValidationProvider',{attrs:{"rules":"required","vid":"billingPostCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-billingPostCode'),expression:"'login-modal-billingPostCode'"}],staticClass:"form__element custom_input",attrs:{"name":"billingPostCode","valid":!errors[0],"error-message":_vm.$t(errors[0]),"placeholder":"12345","label":_vm.$t('Zip-code'),"required":"","autocomplete":"postal-code"},model:{value:(_vm.formCopy.billingPostCode),callback:function ($$v) {_vm.$set(_vm.formCopy, "billingPostCode", $$v)},expression:"formCopy.billingPostCode"}})]}}],null,false,2321800704)})],1),_vm._v(" "),_c('div',{staticClass:"w-100"},[_c('ValidationProvider',{attrs:{"rules":"required","vid":"billingCity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-billingCity'),expression:"'login-modal-billingCity'"}],staticClass:"form__element custom_input",attrs:{"name":"billingCity","valid":!errors[0],"error-message":_vm.$t(errors[0]),"label":_vm.$t('City'),"placeholder":_vm.$t('City'),"required":"","autocomplete":"address-level2"},model:{value:(_vm.formCopy.billingCity),callback:function ($$v) {_vm.$set(_vm.formCopy, "billingCity", $$v)},expression:"formCopy.billingCity"}})]}}],null,false,3668416297)})],1)]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","vid":"billingCountry"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('SfSelect',{staticClass:"form__element custom_select country__select",attrs:{"name":"billingCountry","label":_vm.$t('Country'),"valid":!errors[0],"errorMessage":_vm.$t(errors[0]),"placeholder":_vm.$t('Choose a country'),"required":"","autocomplete":"country"},model:{value:(_vm.formCopy.billingCountry),callback:function ($$v) {_vm.$set(_vm.formCopy, "billingCountry", $$v)},expression:"formCopy.billingCountry"}},_vm._l((_vm.countriesList),function(countryOption){return _c('SfSelectOption',{key:countryOption.id,attrs:{"value":countryOption.id}},[_vm._v("\n                "+_vm._s(countryOption.label)+"\n              ")])}),1)]}}],null,false,1020451353)}),_vm._v(" "),_c('client-only',[_c('input',{staticClass:"custom_payload form_transaction_id",attrs:{"type":"hidden","value":""}}),_vm._v(" "),_c('input',{staticClass:"custom_payload ga_client_id",attrs:{"type":"hidden","value":""}}),_vm._v(" "),_c('input',{staticClass:"custom_payload gclid",attrs:{"type":"hidden","value":""}}),_vm._v(" "),_c('input',{staticClass:"custom_payload fbclid",attrs:{"type":"hidden","value":""}}),_vm._v(" "),_c('input',{staticClass:"custom_payload utm_traffic_source",attrs:{"type":"hidden","value":""}}),_vm._v(" "),_c('input',{staticClass:"custom_payload form_category",attrs:{"type":"hidden","value":"form_registration"}}),_vm._v(" "),_c('input',{staticClass:"custom_payload page_url",attrs:{"type":"hidden","name":"page_url"},domProps:{"value":_vm.getPageUrl}})])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"button__area"},[(_vm.step === _vm.lastStep && _vm.showRecaptcha)?_c('recaptcha'):_vm._e(),_vm._v(" "),_c('SfButton',{staticClass:"btn btn-primary submitBtn",attrs:{"type":"submit","data-testid":"login-form-submit","disabled":_vm.userLoading || _vm.loading}},[_c('SfLoader',{class:{ loader: _vm.userLoading || _vm.loading },attrs:{"loading":_vm.userLoading || _vm.loading}},[_c('div',[_vm._v(_vm._s(_vm.$t(_vm.step === _vm.lastStep ? 'Register' : 'Continue')))])])],1)],1)],2)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }